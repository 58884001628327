<template>
  <div
    class="popup_wrap"
    style="width:900px; height:600px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      닫기
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONIM070P020.001') }}</h1> <!-- 반납지 변경 -->
      <div class="content_box">
        <table class="tbl_col mt10">
          <colgroup>
            <col style="width:5%">
            <col style="width:11%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONIM070P020.002') }}</th> <!-- 선택 -->
              <th>{{ $t('msg.ONIM070P020.035') }}</th> <!-- 반납지 -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="radio" name="checkedCy" id="checkedCy_1" v-model="checkedCy" value="PUSA" checked>
                <label for="checkedCy_1"></label>
              </td>
              <td>{{ $t('msg.ONIM070P020.036') }}</td> <!-- 부산북항 -->
            </tr>
            <tr>
              <td>
                <input type="radio" name="checkedCy" id="checkedCy_2" v-model="checkedCy" value="PUSB">
                <label for="checkedCy_2"></label>
              </td>
              <td>{{ $t('msg.ONIM070P020.037') }}</td> <!-- 부산신항 -->
            </tr>
            <tr>
              <td>
                <input type="radio" name="checkedCy" id="checkedCy_3" v-model="checkedCy" value="INC">
                <label for="checkedCy_3"></label>
              </td>
              <td>{{ $t('msg.ONIM070P020.038') }}</td> <!-- 인천 -->
            </tr>
          </tbody>
        </table>

        <p class="txt_desc">{{ $t('msg.ONIM070P020.039') }}</p> <!-- 반납지 변경요청 이후 리스사 확인을 통해 변경승인/불가가 결정됩니다. 결과를 통지받을 이메일 주소를 기재해 주시길 바랍니다. -->
        <table class="tbl_col mt10">
          <tr>
            <th>{{ $t('msg.ONIM070P020.040') }}</th> <!-- e-mail -->
            <td><input type="text" name="email" id="email" value="" v-model="email" ref="email" @blur.prevent="fnChkEMail"></td>
          </tr>
        </table>

        <div class="mt10 text_center">
          <a class="button blue lg mr3" href="#" @click.prevent="returnChangeRequest">OK</a>
          <a class="button gray lg" href="#" @click.prevent="closePopup">Close</a>
        </div>
      </div><!-- content_box // -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import containerReturn from '@/api/rest/trans/containerReturn'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'ContainerReturn',
  data: function () {
    return {
      checkedCy: '',
      email: ''
    }
  },
  computed: {
    ...rootComputed
  },
  props: {
    propContainerList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  created () {

  },
  mounted () {
  },
  methods: {
    //변경요청 팝업 닫기
    closePopup () {
      console.log('변경요청 팝업 닫기')
      this.customComponent = null
      this.$ekmtcCommon.layerClose()
    },
    //변경요청
    async returnChangeRequest () {
      let cyNm = this.checkedCy
      let cntrNo = ''
      let containerList = this.propContainerList
      let lessor = containerList[containerList.length - 1].rtnLseCstNo
      let blNo = containerList[0].blNo

      if (!this.email) {
        this.$ekmtcCommon.alertDefault($t('msg.ONIM070P020.041')) //e-mail을 입력하세요.
        this.$refs.email.focus()
        return
      }

      if (cyNm) {
        if (containerList.length > 1) {
          containerList.forEach((container) => {
            cntrNo += container.cntrNo + ','
          })
        } else {
          cntrNo = containerList[0].cntrNo
        }
      }

      let data = {
        cyNm: cyNm,
        email: this.email,
        blNo: blNo,
        cntrNo: cntrNo,
        rtnLseCstNo: lessor
      }
      let result = await containerReturn.returnChangeRequestEtc(data)
      let ynFlag = result.data

      if (ynFlag === 'Y') {
        await this.$emit('fnChangeAfterCancel')
        this.$ekmtcCommon.alertDefault($t('msg.ONIM070P020.042')) //변경 가능 여부는 메일로 통지되며\n업무시간 기준 최소 1시간 소요됩니다.\n메일을 받지 못하셨다면 02-311-6127로 연락바랍니다.
      } else {
        this.$ekmtcCommon.alertDefault($t('msg.ONIM070P020.043')) //저장하지 못했습니다.
      }
      this.closePopup()
    },
    //이메일 벨리데이션 체크
    fnChkEMail () {
      if (this.email) {
        // if (/^[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z-]+)*@[0-9a-zA-Z-]+(\.)+([0-9a-zA-Z-]+)([\.0-9a-zA-Z-])*$/.test(this.email) === false ) {
        //   this.$ekmtcCommon.alertDefault('Inappropriate e-mail address type is found.')
        //   this.email= ''
        //   this.$refs.email.focus()
        //   return false
        // }
      }
    }
  }
}
</script>
