var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" 닫기 ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM070P020.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.002")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.035")))]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkedCy,
                        expression: "checkedCy",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "checkedCy",
                      id: "checkedCy_1",
                      value: "PUSA",
                      checked: "",
                    },
                    domProps: { checked: _vm._q(_vm.checkedCy, "PUSA") },
                    on: {
                      change: function ($event) {
                        _vm.checkedCy = "PUSA"
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "checkedCy_1" } }),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.036")))]),
              ]),
              _c("tr", [
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkedCy,
                        expression: "checkedCy",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "checkedCy",
                      id: "checkedCy_2",
                      value: "PUSB",
                    },
                    domProps: { checked: _vm._q(_vm.checkedCy, "PUSB") },
                    on: {
                      change: function ($event) {
                        _vm.checkedCy = "PUSB"
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "checkedCy_2" } }),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.037")))]),
              ]),
              _c("tr", [
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkedCy,
                        expression: "checkedCy",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "checkedCy",
                      id: "checkedCy_3",
                      value: "INC",
                    },
                    domProps: { checked: _vm._q(_vm.checkedCy, "INC") },
                    on: {
                      change: function ($event) {
                        _vm.checkedCy = "INC"
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "checkedCy_3" } }),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.038")))]),
              ]),
            ]),
          ]),
          _c("p", { staticClass: "txt_desc" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONIM070P020.039"))),
          ]),
          _vm._v(" "),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.040")))]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                  ],
                  ref: "email",
                  attrs: {
                    type: "text",
                    name: "email",
                    id: "email",
                    value: "",
                  },
                  domProps: { value: _vm.email },
                  on: {
                    blur: function ($event) {
                      $event.preventDefault()
                      return _vm.fnChkEMail.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg mr3",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.returnChangeRequest.apply(null, arguments)
                  },
                },
              },
              [_vm._v("OK")]
            ),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closePopup.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Close")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }